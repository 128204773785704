<template>
  <router-view></router-view>
</template>

<script>
import RestApi , { baseUrl } from './config/api_config'
export default {
  name: 'App',
  data (){
    return {
      name: ''
    }
  }
}

</script>

<style lang="scss">
  @import 'assets/scss/style';
</style>
